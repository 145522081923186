<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/MA-Ferrules-Rev1-210610-1536x1044.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">


                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Many years of experience in manufacturing of crimp applicators has led to a high
                                        quality and high technological product. </p>
                                    <br>
                                    <p style="font-size: 16px; color: black; font-weight: 400">
                                        Accurate choice of materials and attention to working and treatment processes of
                                        all parts of a crimp applicator make this product long lasting and reliable. The
                                        construction concept is a product adaptable to main manual and automatic crimp
                                        units available in the market and easy to customise for any requested terminal.
                                        Our basic version includes the 4-position head for height adjustment. Each crimp
                                        applicator is delivered complete with instructions handbook saved on pen drive
                                        and crimped samples done during the factory acceptance test. </p>
                                </div>

                                <br />
                                <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">

                                    PNEUMATIC CRIMP APPLICATOR FOR FERRULES – MODEL TMFP1
                                </h1>
                                <br />
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    This model has been specifically made for processing FERRULES for terminals of type
                                    WIRE-END SLEEVE to be fed arranged in chain. Feeding is done by means of a pneumatic
                                    cylinder mounted on the applicator and acting directly on the feeding motion of the
                                    terminals. The applicator is equipped with a window-type centring system for
                                    facilitating the insertion and positioning of the wire to be crimped.
                                </p>

                                <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    AVAILABLE OPTIONS
                                </h1>
                                <br>
                                <h3>MICROMETRIC WHEEL</h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    This micrometric device is composed of two overlapped ring nuts and a shared
                                    reference index. The upper ring nut, distinguished by a series of letters, allows
                                    the adjustment of the pressure on the conductor whilst the second ring nut,
                                    distinguished by a series of numbers, allows the adjustment of the pressure on the
                                    insulation. At every turn both ring nuts perform the minimum incremental
                                    displacement 0.02 mm. The
                                    advantage of this system is to offer immediate reading of the indexes and
                                    the possibility to discriminate different types of insulation – at equal cross
                                    section of the conductor (for ex. Standard, Phase 3 etc.). This applicator allows,
                                    additionally, to avoid any intervention on crimping units having different
                                    Shut-Height values. It is also possible to exchange the micrometric device with the
                                    basic version -the graduated wheel- without need to intervene on other parts of the
                                    applicator’s assembly.
                                </p>

                                <br />
                                <h3>COUNTER</h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    Applicators can be delivered equipped with non-resettable counter to allow
                                    monitoring the useful life of components and programme the replacement of the
                                    applicator’s crimping tools.
                                </p>

                                <br />



                                <p style="font-size: 14px; color: black; font-weight: 400">

                                    Data and measurements are indicative and subject to change without notice. Some
                                    particular types of cables may not be machined even though they fall within the
                                    range of sections indicated. KM Corporate will still be happy to carry out tests and
                                    supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br />
                        <br />
                        <br />
                        <div class="row">
                            <div class="col-5"></div>
                            <div class="col-7">
                                <button @click="file()" class="btn btn-primary" style="width:100%"><i
                                        class="fa fa-download"></i> Download The Technical Sheet</button>
                            </div>
                        </div>
                        <br><br><br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800; margin-left: 40px" class="font-size-20 mb-3">
                                Gallery

                            </h1>

                        </div>
                        <br />
                        <div class="row" style="margin-left:320px;">

                            <vue-picture-swipe :items="items"></vue-picture-swipe>
                        </div>
                        <!-- end row -->


                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {

        Header,
        Footer,


    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-3-rev1-210611-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-3-rev1-210611-150x150.jpg',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-2-rev1-210611-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-2-rev1-210611-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-1-rev1-210611-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/MINIAPPLICATORE-DETTAGLI-1-rev1-210611-150x150.jpg',
                w: 1200,
                h: 900
            },

            ]
        };

    },

    methods: {
        file() {
            window.location.href = "https://kmcorporate.com/wp-content/uploads/2021/06/Scheda_Mini_Rev-19-01-Low.pdf";
        }
    }
};
</script>